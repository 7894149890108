<template>
  <div class="recognition">
    <el-upload
      class="upload-demo"
      drag
      accept=" image/*"
      action="/"
      :limit="9999"
      :file-list="fileList"
      list-type="picture"
      :on-change="uploadImage"
      :on-remove="handleRemove"
      :auto-upload="false"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
    </el-upload>
    <!-- <img src=""> -->
    <div class="result_all">
      <div class="result" v-if="imageInfo.text">
        图片名称:{{ imageInfo.text }}
      </div>
      <div class="result" v-if="imageInfo.confidence">
        图片置信度:{{ imageInfo.confidence }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      fileList: [], //图片列表
      imageInfo: {}, //图片信息
    };
  },

  methods: {
    uploadAgin(files) {
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const formData = new FormData();
      formData.append("file", files);
      axios
        .post("http://ds.srthinker.com/api/image-to-text", formData, {
          "Content-type": "multipart/form-data",
        })
        .then(
          (res) => {
            loading.close();
            if (res.status == 200) {
              this.imageInfo = res.data;
            }
          },
          (err) => {
            // 出现错误时的处理
            this.$message({
              message: err.msg,
              type: "error",
            });
          }
        );
    },
    // 选择图片上传钩子
    uploadImage(file, fileList) {
      this.fileList = [file];
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var files = file.raw;
      const formData = new FormData();
      formData.append("file", files);
      axios
        .post("http://ds.srthinker.com/api/image-to-text", formData, {
          "Content-type": "multipart/form-data",
        })
        .then(
          (res) => {
            loading.close();
            if (res.status == 200) {
              this.imageInfo = res.data;
            }
          },
          (err) => {
            // 出现错误时的处理
            this.$message({
              message: err.msg,
              type: "error",
            });
          }
        );
    },

    // 删除图片
    handleRemove(file, fileList) {
      this.imageInfo = {};
      this.fileList = [];
    },
  },
};
</script>

<style scoped>
.recognition {
  padding-top: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.result {
  margin-top: 50px;
}
.result_all {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
::v-deep .el-upload-list {
  margin: 0 auto;
  width: 300px;
}
</style>