import Vue from "vue";
import VueRouter from "vue-router";
import Result from "../components/result.vue";
import Comment from "../components/comment.vue";
import Vivoauthentication from "../components/vivoauthentication.vue";
import Identification from "../components/identification.vue";
import Raiseplacard from "../components/raiseplacard.vue";
import Hello from "../components/HelloWorld.vue";
import Specialresult from "../components/specialresult.vue";
import Newresult from "../components/newresult.vue";
import Webview from "../components/webview.vue";
import Gas from "../components/gas.vue";
import ImageRecog from "../components/imageRecognition.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/comment",
  },
  {
    path: "/hello",
    name: "hello",
    component: Hello,
    meta: {
      title: "活体检测",
    },
  },
  {
    path: "/specialresult",
    name: "specialresult",
    component: Specialresult,
    meta: {
      title: "验证结果",
    },
  },
  // 公用头部
  {
    path: "/comment",
    name: "comment",
    redirect: "vivoauthentication",
    component: Comment,
    children: [
      {
        path: "/vivoauthentication",
        name: "vivoauthentication",
        component: Vivoauthentication,
        meta: {
          title: "活体检测",
        },
      },
      {
        path: "/identification",
        name: "identification",
        component: Identification,
        meta: {
          title: "身份识别",
        },
      },
      {
        path: "/raiseplacard",
        name: "raiseplacard",
        component: Raiseplacard,
        meta: {
          title: "举牌稽核",
        },
      },
    ],
  },
  {
    path: "/result",
    name: "result",
    component: Result,
    meta: {
      title: "验证结果",
    },
  },
  {
    path: "/webview",
    name: "webview",
    component: Webview,
    meta: {
      title: "活体检测",
    },
  },
  {
    path: "/newresult",
    name: "newresult",
    component: Newresult,
    meta: {
      title: "新验证结果",
    },
  },
  {
    path: "/gas",
    name: "gas",
    component: Gas,
    meta: {
      title: "燃气",
    },
  },
  {
    path: "/imageRecog",
    name: "imageRecog",
    component: ImageRecog,
    meta: {
      title: "图片识别",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//2.使用router.beforeEach对路由进行遍历，设置title
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    console.log(to.meta.title);
    document.title = to.meta.title;
  } else {
    document.title = "默认title";
  }
  next();
});

export default router;
